@use '../../styles/mixins' as mixins;
@use '../../styles/breakpoints' as bp;

.share-class-grid {

	@include mixins.grid(400px);

	.share-class {
		border: solid 1px var(--wp--preset--color--bnpp-black);
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		position: relative;

		@media screen and (max-width: bp.$breakpoint-small) {
			flex-direction: column;
			flex-wrap: nowrap;
		}

		&:hover {

			.share-class-content {
				background-color: var(--wp--preset--color--dark-green);
				
				h3 {
					color: var(--wp--preset--color--white);
				}

				.learn-more {
					color: #7DCBBA;
				}
			}

			@include mixins.hoverAnimationsStart;

		}

		.learn-more  {
			color: var(--wp--preset--color--bnpp-green);
			text-decoration-thickness: 1px;
			margin: 0;

			&::after {
				content: " \2192";
				font-family: system-ui;
			}
		}

		.share-class-content {
			transition: 300ms;
			flex: 1 1 200px;
			padding: var(--wp--preset--spacing--60);
			display: flex;
			flex-direction: column;
			height: 100%;

			@media screen and (max-width: bp.$breakpoint-small) {
				flex: 1;
				width: 100%;
			}

			h3 {
				color: var(--wp--preset--color--bnpp-black);
			}
		}

		.icon {
			flex: 0 1 200px;
			position: relative;
			height: 100%;
    		display: flex;

			@media screen and (max-width: bp.$breakpoint-small) {

				position: static;
				
				svg {
					margin: 0 auto;
					max-width: 200px;
					width: 100%;
				}

			}

			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 1px;
				background: var(--wp--preset--color--bnpp-black);

				@media screen and (max-width: bp.$breakpoint-small) {
					height: 1px;
					width: 100%;
					bottom: min(100%, 200px);
				}
			}

			& {
				@include mixins.hoverKeyframes;
			}

		}
		
	}

	&.dark {


		.share-class {
			border: solid 1px var(--wp--preset--color--white);

			&:hover {

				.share-class-content  {

					background-color: var(--wp--preset--color--white);

					h3 {
						color: var(--wp--preset--color--bnpp-black);
					}
	
					.learn-more {
						color: var(--wp--preset--color--bnpp-green);
					}
				}

			}

			.share-class-content  {

				@media screen and (max-width: bp.$breakpoint-small) {
					&:has(+.icon) {
						border-bottom: solid 1px var(--wp--preset--color--white);
					}
				}

				h3 {
					color: var(--wp--preset--color--white);
				}

				.learn-more {
					color: #7DCBBA;
				}
			}
		}

		.icon {
			border-left: solid 1px var(--wp--preset--color--white);

			@media screen and (max-width: bp.$breakpoint-small) {
				border-left: none;
			}
		}

	}

}